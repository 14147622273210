<template>
  <div class="l-main-block mobileFullWidth clearfix">
    <div class="l-main mobileFullWidthWithMargin">
      <div class="vm-productview">
        <looping-rhombuses-spinner
          :animation-duration="2500"
          :rhombus-size="15"
          color="#febe10"
          v-if="show_spinner"
          class="loader-spinner"
        />
        <div v-if="!show_spinner" class="layout-set layout-set--4-6 item">
          <div class="section-left block-1">
            <div>
              <div>
                <div class="vm-image-box">
                  <a
                    :title="book.isbn + '.jpg'"
                    rel="vm-additional-images"
                    :href="getCoverLinkSmall(book.isbn)"
                  >
                    <img
                      :src="getCoverLinkSmall(book.isbn)"
                      :alt="book.isbn + '.jpg'"
                      @error="noCoverImage"
                    />
                  </a>
                  <vue-gallery
                    :images="get_images"
                    :index="index"
                    @close="index = null"
                  ></vue-gallery>
                  <div class="clear"></div>
                </div>
                <div class="image-gallery" v-if="get_images.length > 1">
                  <div
                    class="image"
                    v-for="(image, imageIndex) in get_images"
                    :key="imageIndex"
                    @click="index = imageIndex"
                    :style="{
                      'max-width': '70px',
                      height: '100%',
                      'margin-right': '15px',
                    }"
                  >
                    <img :src="image" alt />
                  </div>
                </div>
              </div>
              <div>
                <div class="spacer-buy-area">
                  <add-to-cart
                    :isbn="book.isbn"
                    :title="book.book.nonsortprefix + ' ' + book.book.title"
                    :book="book"
                    place="browse-book"
                  ></add-to-cart>

                  <div
                    class="downloadImage"
                    v-if="cover_for_download_available"
                  >
                    <a
                      class="button button--color2 button--fullwidth"
                      :href="getCoverLinkOriginal(book.isbn)"
                      :download="book.isbn + '.jpg'"
                      target="_blank"
                      >Download cover</a
                    >
                  </div>

                  <div class="downloadImage" v-for="link in get_other_links">
                    <div v-if="link.source != 'video author'">
                      <a
                        v-if="link.url"
                        class="button button--color2 button--fullwidth"
                        :href="link.url"
                        target="_blank"
                        >Download {{ link.source }}</a
                      >
                    </div>
                  </div>
                  <div class="downloadImage" v-for="link in get_box_links">
                    <a
                      v-if="link.url"
                      class="button button--color2 button--fullwidth"
                      :href="link.url"
                      target="_blank"
                      >Download Box {{ link.source }}</a
                    >
                  </div>
                  <div class="mt-40"v-if="retailer_links.length != 0">
                    <label
                        class="header-top"
                        >Find Local Retailers:</label
                      > 
                    <div class="grid-block">
                      <div v-for=" links in retailer_links" :class="{'div-b': host === 'allenandunwin' || host === 'allenandunwinnz', 'div-m': host === 'murdoch' || host === 'murdochuk'}">
                        <a :href="book_seller_url(links)" target="_blank">
                          <img class="logo-block" :src="links.logo_link" alt="Book Seller Logo" />
                        </a>
                      <a :href="book_seller_url(links)" target="_blank">
                        <label>{{ links.bookseller_name }}</label>
                       </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="clear"></div>
            </div>
            <!-- <div class="ship-eta-search">
              <span v-if="getShipETA">{{getShipETA}}</span>
            </div> -->
          </div>
          <div class="section-right block-2">
            <h1 class="title-size">
              {{ book.book.nonsortprefix }} {{ book.book.title }}
            </h1>
            <p class="product-subtitle">{{ book.book.subtitle }}</p>
            <div class="vm-product-fields">
              <div
                class="product-field product-field-type-S product-field-author"
              >
                <span class="product-field-display"
                  >by {{ book.book.author }}</span
                >
                <span class="product-field-desc"></span>
              </div>
            </div>
            <div class="product-price">
              <span class="price-crossed"></span>
              <div class="PricesalesPrice" style="display: block">
                <span class="PricesalesPrice"
                  >{{ currencySymbol }}{{ productPrice }}</span
                >
              </div>
              <div class="PricediscountAmount" style="display: none">
                Discount:
                <span class="PricediscountAmount"></span>
              </div>
              <div class="PriceunitPrice" style="display: none">
                Price / COM_VIRTUEMART_UNIT_SYMBOL_:
                <span class="PriceunitPrice"></span>
              </div>
            </div>
            <p
              class="product-short-description text-large"
              v-if="book.blurb.short_description"
              v-html="book.blurb.short_description"
            ></p>
            <p
              class="product-keynote"
              v-if="book.blurb.key_note_blurb"
              v-html="book.blurb.key_note_blurb"
            ></p>
            <div
              class="product-description"
              v-if="book.blurb.product_description"
              v-html="book.blurb.product_description"
            ></div>
            <div
              class="product-description"
              v-else
              v-html="book.blurb.cover_blurb"
            ></div>
            <div class="more-books-button-group">
              <router-link
                class="button button--color2 button-max"
                :to="'/search?author=' + encodeURI(book.book.author)"
                >More books by this author</router-link
              >
              <br />
              <router-link
                v-if="book.book.series"
                class="button button--color1 button-max"
                :to="'/search?series=' + encodeURI(book.book.series)"
                >More books in this series</router-link
              >
            </div>

            <!-- <h4 class="header-top" v-if="book.blurb.quotes">Reviews:</h4>
            <p v-html="book.blurb.quotes"></p> -->
            <h4 class="header-top" v-if="book.blurb.author_bio">Author bio:</h4>
            <p v-html="book.blurb.author_bio"></p>
            <div class="vm-product-fields-table">
              <div class="vm-product-fields">
                <div
                  v-if="host != 'murdoch'"
                  class="product-field product-field-type-S"
                >
                  <span class="product-fields-title">Category:</span>
                  <span class="product-field-display">{{
                    book.bic1.marketing_main_description
                  }}</span>
                  <span class="product-field-desc"></span>
                </div>
                <div class="product-field product-field-type-S">
                  <span class="product-fields-title">ISBN:</span>
                  <span class="product-field-display">{{ book.isbn }}</span>
                  <span class="product-field-desc"></span>
                </div>
                <div
                  class="product-field product-field-type-S product-field-awards"
                  v-if="book.awards.length > 0"
                >
                  <span class="product-fields-title">Awards:</span>
                  <span class="product-field-display">
                    <ul class="book-award">
                      <li v-for="award in book.awards" class="awards-list">
                        {{ award }}
                      </li>
                    </ul>
                  </span>
                  <span class="product-field-desc"></span>
                </div>
                <div
                  class="product-field product-field-type-S product-field-publisher"
                >
                  <span class="product-fields-title">Publisher:</span>
                  <span class="product-field-display">{{ book.il3_desc }}</span>
                  <span class="product-field-desc"></span>
                </div>
                <div
                  class="product-field product-field-type-S product-field-imprint"
                >
                  <span class="product-fields-title">Imprint:</span>
                  <span class="product-field-display">{{
                    book.imprint.description
                  }}</span>
                  <span class="product-field-desc"></span>
                </div>
                <div
                  class="product-field product-field-type-S product-field-pub date"
                >
                  <span class="product-fields-title">Pub Date:</span>
                  <span class="product-field-display">{{ get_pub_date }}</span>
                  <span class="product-field-desc"></span>
                </div>
                <div
                  class="product-field product-field-type-S product-field-page extent"
                >
                  <span class="product-fields-title">Page Extent:</span>
                  <span class="product-field-display">{{
                    book.book.page_extent
                  }}</span>
                  <span class="product-field-desc"></span>
                </div>
                <div
                  class="product-field product-field-type-S product-field-format"
                >
                  <span class="product-fields-title">Format:</span>
                  <span class="product-field-display">{{
                    book.format.description
                  }}</span>
                  <span class="product-field-desc"></span>
                </div>
                <div
                  v-if="book.package.description"
                  class="product-field product-field-type-S product-field-format"
                >
                  <span class="product-fields-title">Package type:</span>
                  <span class="product-field-display">{{
                    book.package.description
                  }}</span>
                  <span class="product-field-desc"></span>
                </div>
                <div
                  v-if="book.book.edition"
                  class="product-field product-field-type-S product-field-format"
                >
                  <span class="product-fields-title">Edition number:</span>
                  <span class="product-field-display">{{
                    book.book.edition
                  }}</span>
                  <span class="product-field-desc"></span>
                </div>
                <div
                  v-if="book.series_description"
                  class="product-field product-field-type-S product-field-format"
                >
                  <span class="product-fields-title">Series name:</span>
                  <span class="product-field-display">{{
                    book.series_description
                  }}</span>
                  <span class="product-field-desc"></span>
                </div>
                <div
                  v-if="book.book.num_in_series"
                  class="product-field product-field-type-S product-field-format"
                >
                  <span class="product-fields-title">Series number:</span>
                  <span class="product-field-display">{{
                    book.book.num_in_series
                  }}</span>
                  <span class="product-field-desc"></span>
                </div>
                <div
                  class="product-field product-field-type-S product-field-age"
                  v-if="age"
                >
                  <span class="product-fields-title">Age:</span>
                  <span class="product-field-display">{{ age }}</span>
                  <span class="product-field-desc"></span>
                </div>
                <div
                  class="product-field product-field-type-S product-field-subject"
                >
                  <span class="product-fields-title">Subject:</span>
                  <span class="product-field-display">{{
                    book.bic1.description
                  }}</span>
                  <span class="product-field-desc"></span>
                </div>
                <div
                  class="product-field product-field-type-S product-field-table-of-contents"
                  v-if="book.blurb.table_of_contents"
                >
                  <span class="product-fields-title">Table of Contents:</span>
                  <span
                    class="product-field-display"
                    v-html="book.blurb.table_of_contents"
                  ></span>
                  <span class="product-field-desc"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="clear"></div>
        </div>
        <div class="underbib-modules"></div>
      </div>
    </div>
    <br /><br />
    <div v-if="show_iframe" class="sample-chapter-iframe">
      <h3><a name="excerpt"></a>Read an excerpt:</h3>
      <p>
        <a
          :href="getIframeUrl"
          style="
            text-decoration: underline;
            font-family: Helvetica, Arial, sans-serif;
            font-size: 14px;
          "
          :title="'View ' + book.book.title + ' Chapter Sampler on Scribd'"
          >{{ book.book.title }}</a
        >
        <span style="font-family: Helvetica, Arial, sans-serif; font-size: 14px"
          >&nbsp;by&nbsp;</span
        >
        <a
          :href="getIframeUrl"
          style="
            text-decoration: underline;
            font-family: Helvetica, Arial, sans-serif;
            font-size: 14px;
          "
          title="View Allen &amp; Unwin's profile on Scribd"
          >Allen &amp; Unwin</a
        >
      </p>
      <iframe
        :src="getIframeUrl"
        frameborder="0"
        scrolling="no"
        width="100%"
        height="600"
        class="scribd_iframe_embed"
        :title="book.book.title"
        tabindex="0"
        data-auto-height="true"
        data-aspect-ratio="0.653372008701958"
      ></iframe>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { LoopingRhombusesSpinner } from "epic-spinners";
import AddToCart from "../components/AddToCart";
import Currency from "../mixins/Currency";
import VueGallery from "vue-gallery";

export default {
  name: "browse-book",
  components: {
    LoopingRhombusesSpinner,
    AddToCart,
    VueGallery,
  },
  mixins: [Currency],
  data() {
    return {
      retailer_links: [],
      book: {
        isbn: 0,
        title: "-",
        awards: [],
        book: {
          title: "-",
        },
        blurb: {
          keywords: null,
        },
        author: "-",
        retail_price_aus: 0.0,
        package_type: "-",
      },
      cover_for_download_available: true,
      show_spinner: true,
      index: null,
      filename: null,
      show_iframe: false,
      host: "",
    };
  },
  methods: {
    book_seller_url(links) {
    // Format the title and author
    let title = this.book.book.title.replace(/[()]/g, '').replace(/:/g, '').replace(/\s+/g, '-');
    let author = this.book.book.author.replace(/\s+/g, '-');

    // Check for Fishpond and adjust URL accordingly
    if (links.bookseller_name === 'Fishpond') {
      // Fishpond URLs are case-sensitive and typically capitalized
      title = title;
      author = author;
    } else {
      // Other sellers' URLs are usually in lowercase
      title = title.toLowerCase();
      author = author.toLowerCase();
    }

    // Replace placeholders in the URL extension with actual values
    let url_extension = links.url_extension
      .replace('{ISBN}', this.book.book.isbn)
      .replace('{title}', title)
      .replace('{author}', author);

    // Return the complete URL
    return `${links.main_url}${url_extension}`;
  },
    noCoverImage(event) {
      if (this.$store.state.site_data.host== 'allenandunwin' || this.$store.state.site_data.host== 'allenandunwinnz'){
        event.target.src = "https://s3.ap-southeast-2.amazonaws.com/assets.allenandunwin.com/other/A%26U_Holding_Image.jpg";
        this.cover_for_download_available = false;
      }
      else{
        event.target.src = "/static/Holding-Image-DIMO.jpg";
        this.cover_for_download_available = false;
      }
      
    },
    getCoverLinkSmall(isbn) {
      return `https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/images/small/${isbn}.jpg`;
    },
    getCoverLinkOriginal(isbn) {
      return `https://s3-ap-southeast-2.amazonaws.com/assets.allenandunwin.com/images/original/${isbn}.jpg`;
    },
  },
  metaInfo() {
    // TODO add title here
    return {
      meta: [{ name: "keywords", content: this.book.blurb.keywords }],
    };
  },
  computed: {
    productPrice() {
      let region = this.$store.state.site_data.region;
      return this.book.book[`retail_price_${region}`];
    },
    age() {
      if (
        this.book.book.kids_readership_from &&
        this.book.book.kids_readership_to
      ) {
        return `${this.book.book.kids_readership_from} - ${this.book.book.kids_readership_to}`;
      }
      return null;
    },
    get_images() {
      let images_arr = [];
      for (let i = 0; i < this.book.links.length; i++) {
        const link = this.book.links[i];
        if (link.type == "image") {
          if (link.url !== null) {
            images_arr.push(link.url);
          }
        }
      }
      return images_arr;
    },
    get_other_links() {
      let other_links = [];
      for (let i = 0; i < this.book.links.length; i++) {
        let link = this.book.links[i];
        if (link.type == "other") {
          link.source = link.source.replace(/_/g, " ");
          other_links.push(link);
        }
      }
      return other_links;
    },
    getIframeUrl() {
      return (
        "https://www.scribd.com/embeds/" +
        this.filename +
        "/content?start_page=1&view_mode=scroll&access_key=key-gkn5V9qrAdQacrhtzRPd"
      );
    },
    get_box_links() {
      let links = [];
      for (let i = 0; i < this.book.links.length; i++) {
        let link = this.book.links[i];
        if (link.type == ".ext") {
          link.source = link.source.replace(/_/g, " ");
          links.push(link);
        }
      }
      return links;
    },
    get_pub_date() {
      var monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      var test = "uk";
      if (document.location.hostname.search(test) != -1) {
        var dt = this.book.book.uk_pub_date;
      } else {
        var dt = this.book.book.pubdate;
      }
      const dateObj = new Date(dt);
      const year = dateObj.getFullYear();
      const month = dateObj.getMonth();
      const date = dateObj.getDate().toString().padStart(2, "0");
      const pub_date = `${date} ${monthNames[month]} ${year}`;
      return pub_date;
    },
    getShipETA() {
      let stock_date = new Date(this.book.stock_availability_date);
      let current_date = new Date();
      var millisecondsPerDay = 24 * 60 * 60 * 1000;
      let date_diff = Math.round(
        (stock_date - current_date) / millisecondsPerDay
      );
      let pubdate = null;
      if (this.book.book) {
        pubdate = new Date(this.book.book.pubdate);
      } else {
        pubdate = new Date(this.book.pubdate);
      }
      if (stock_date <= pubdate) {
        if (
          this.book.stock_availability_date &&
          this.book.stock_availability_date != "0000-00-00" &&
          this.$store.state.site_data.ecommerce
        ) {
          let format_date = new Date(this.book.stock_availability_date).toLocaleDateString(
            'en-gb',
            {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            }
          );
          return `Ships on ${format_date}`;
        } else {
          return "";
        }
      } else if (date_diff <= 5) {
        return "Ships within 5 days";
      } else if (date_diff <= 7) {
        return "Ships within 5-7 days";
      } else if (date_diff < 14) {
        return "Ships in 1-2 weeks";
      } else if (date_diff < 21) {
        return "Ships in 2-3 weeks";
      } else if (date_diff < 28) {
        return "Ships in 3-4 weeks";
      } else if (date_diff < 30) {
        return "Ships in 1 month";
      } else if (date_diff < 60) {
        return "Ships in 1-2 months";
      } else if (date_diff < 90) {
        return "Ships in 2-3 months";
      } else if (date_diff < 120) {
        return "Ships in 4 months";
      } else {
        return "";
      }
    },
    //  get_now_date() {
    //   var monthNames = [
    //     "Jan",
    //     "Feb",
    //     "Mar",
    //     "Apr",
    //     "May",
    //     "Jun",
    //     "Jul",
    //     "Aug",
    //     "Sep",
    //     "Oct",
    //     "Nov",
    //     "Dec",
    //   ];
    //   const dateObj = new Date();
    //   const year = dateObj.getFullYear();
    //   const month = dateObj.getMonth();
    //   const date = dateObj.getDate().toString().padStart(2, "0");
    //   const now_date = `${date} ${monthNames[month]} ${year}`;
    //   return now_date;
    // },
  },
  mounted() {
    this.host = this.$store.state.site_data.host;
  },

  beforeCreate() {
    let self = this;
    let url = this.$route.params.url;
    let isbn = url.split("-");
    isbn = isbn[isbn.length - 1];

    this.$route.meta.breadcrub = [
      { name: "Home", link: "home" },
      { name: "Search", link: "browse" },
    ];
    axios
      .get(`/get_book_data/${isbn}/`)
      .then(function (data) {
        self.book = data.data;
        self.show_spinner = false;
        for (let i = 0; i < self.book.links.length; i++) {
          let link = self.book.links[i];
          if (link.source == "read_excerpt" && link.link_only == 1) {
            self.filename = link.filename;
            self.show_iframe = true;
          }
        }
        document.title = `${self.book.book.title} - ${self.book.book.author} -${self.book.book.series}- ${self.book.il3_desc} - ${self.book.book.isbn} - ${self.$store.state.site_data.title}`;
        // self.metaInfo.meta.push({name: 'keywords', content: self.book.blurb.keywords});
        self.$route.meta.breadcrub.push({
          name: self.book.book.nonsortprefix + " " + self.book.book.title,
        });
      })
      .catch(function (err) {
        console.log(err);
        window.location = "/404/";
      });
    axios
        .get(`/buy-bookseller-links/?isbn=${isbn}`)
        .then(function (data) {
          self.retailer_links = data.data
        })
        .catch(function (err) {
          console.log(err);
      });
    // invoke the title of the book to the breadcrub
    axios
      .get("/get_book_breadcrub/", {
        params: {
          isbn: isbn,
        },
      })
      .then(function (data) {
        let breadcrub_data = data.data.data;

        for (let i = 0; i < breadcrub_data.length; i++) {
          const element = breadcrub_data[i];

          self.$route.meta.breadcrub.push({
            name: element.title,
            path: "/search?bic=" + element.code,
          });
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  },
};
</script>

<style scoped>

.grid-block {
  margin-top: 15px;
}
.logo-block{
    -o-object-fit: cover;
    object-fit: cover;
    display: block;
    margin: 0 auto;
    width: 40px;
    padding: 5px;
    min-height: 40px;
    min-width: 40px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.div-b{
    border: 2px solid gold;
    border-radius: 15px;
    display: flex;
    text-align: center;
    align-items: center;
    width: 56%;
    margin-bottom: 20px
}
.div-m{
    border: 2px solid #004C6F;
    border-radius: 15px;
    display: flex;
    text-align: center;
    align-items: center;
    width: 56%;
    margin-bottom: 20px
}
.mt-40 {
    margin-top: 40px;
}
a.button.button--color2.button-max {
  min-width: 234px;
}

a.button.button--color1.button-max {
  min-width: 234px;
}
.awards-list {
  list-style-type: none;
}
.l-main-wrapper,
.l-right,
.l-left {
  float: left !important;
}
.image-gallery {
  display: inline-flex;
  padding-bottom: 20px;
}
.vm-productview .downloadImage a:after {
  left: 85% !important;
}
.book-award {
  padding: 0 0 0 0;
  margin: 0 0 1em 0;
}
.header-top {
  margin-top: 1.5em;
}
.title-size {
  font-size: 1.477em;
}
.product-keynote {
  font-weight: bold;
  margin-bottom: 0.5em;
}
@media screen and (max-width: 767px) {
  .header-top {
    margin-top: 1.5em;
  }
}
</style>